<template>
  <div class="ClassIf">
    <van-nav-bar :title="$t('categories')" :placeholder="true" fixed @click-right="toHistory">
      <template #right>
        <van-icon  name="search" size=".5rem" />
      </template>
    </van-nav-bar>

    <div class="tabs-content">
      <!-- 筛选 -->
      <!-- 第一层 -->
      <van-tabs @click="getTypeID">
        <van-tab v-for="item in typeInfo" :key="item.id" :title="item.name" :name="item.id">
          <div style="height: 7px;"></div>  <!--第一级和第二级之间的间距-->
          <van-tabs v-for="(towType, index) in item.msg"
                    :key="index"
                    :ellipsis="false"
                    @click="getCondition"
                    v-model="screenParams[towType.name]"
          >
            <van-tab v-for="(info, index) in towType.data"
                     :title="info"
                     :key="index"
                     :name="info"
            >
            </van-tab>
          </van-tabs>
        </van-tab>
      </van-tabs>

    </div>
    <!-- 筛选出的影片 -->
    <van-list v-model="loading" :loading-text="$t('loading')" :finished="finished"
              :immediate-check="false"
              @load="onLoad">
      <van-empty :image="require('@/assets/empty-image-default.png')" v-show="screenInfo.length <= 0 && ! loading"/>
      <div class="screenMovie">
        <div v-for="item in screenInfo" :key="item.id" class="screenMovie_cell" @click="toPlays(item.id)">
          <div style="position:relative">
            <van-image :src="item.vod_pic" class="image_item" />
            <audioLangTag v-show="$app_country === 'hi'" :content="item.audio_language_tag"/>
          </div>
          <div class="trainee-idol van-ellipsis">{{ item.vod_name }}</div>
        </div>
      </div>
    </van-list>
    <div v-if="isBackTop" class="back-top" @click="backTop">
      <van-button round type="info" color="var(--minor-color)"><van-icon name="back-top" /></van-button>
    </div>
    <layout></layout>
  </div>
</template>
<script>
import { getTypeList, getScreen } from '@/api/Classificacao/index'
import layout from '@/layout/index'
import audioLangTag from "@/components/audioLangTag";

export default {
  name: 'class',
  components: {
    layout,
    audioLangTag
  },
  data() {
    return {
      typeInfo: [], // 筛选的总数据 （也就是第一层tab的数据）
      typeNum: null, //选中第一个的参数
      towTypeInfo: [], //第一层下面的数据
      screenInfo: [], //筛选出来的数据
      screenParams: {},
      loading: false,
      finished: false,
      isBackTop: false
    }
  },
  // 计算属性
  computed: {},
  watch: {},
  mounted() {
    this.getTypeLists()
    this.$parent.excludeKeepAlive([])
  },
  methods: {
    getTypeLists() {
      getTypeList().then(res => {
        this.typeInfo = res.result
        this.getScreenParams()
      })
    },
    getScreenParams(type_id = 1){
      let params = {
        type_id: type_id,
        pn: 1
      }
      let data = []
      for (let i = 0; this.typeInfo.length; i++) {
        if (this.typeInfo[i].id === type_id) {
          data = this.typeInfo[i].msg
          break
        }
      }
      for (let i = 0; i < data.length; i++) {
        params[data[i].name] = data[i].data[0]
      }

      this.screenParams = params

      this.loading = true
      this.finished = false
      this.onLoad()
    },
    // 获取筛选的类型
    getTypeID(name) {
      let type_id = name || 1
      this.screenInfo = []
      this.getScreenParams(type_id)
    },

    // 获取筛选条件
    getCondition() {
      this.screenParams.pn = 1
      this.screenInfo = [];
      this.loading = true
      this.finished = false
      this.onLoad()
      console.log(this.screenParams)
    },
    // 顶部nav右侧
    toHistory() {
      localStorage.setItem('path', this.$route.path)
      this.$router.push('/search/historyRecommend')
    },
    // 去播放页面
    toPlays(vod_id) {
      const query = {
        vod_id,
      }
      this.$router.push({
        path: '/play',
        query
      })
    },
    // 无限刷新
    onLoad() {
      // 异步更新数据
      const _this = this
      console.log(this.screenParams)
      let pn = this.screenParams.pn
      getScreen(this.screenParams).then(res => {
        if (res.result.length === 0) {
          this.finished = true;
          this.loading = false
        } else {
          for (const item of res.result) {
            this.screenInfo.push(item)
          }
          this.loading = false
          _this.screenParams.pn = pn + 1
        }
      })
      if (pn > 2) {
        // this.isBackTop = true
      }
    },
    backTop(){
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 平滑滚动效果，需浏览器支持
      })
      this.isBackTop = false
    }
  }
}
</script>
<style lang="scss" scoped>

/deep/.van-nav-bar {
  margin-bottom: 20px;
  border-bottom: 1px solid var(--minor-color);
}

/deep/.van-nav-bar__title {
  font-weight: bold;
}

//每行筛选条件的 整行
/deep/.van-tabs__nav {
  background: none;
}

//所有条件未选中的文字样式
/deep/.van-tab {
  font-size: 14px;
  font-weight: normal;
  //line-height: 66px;
  letter-spacing: 0px;
  color: var(--minor-text-color);
  -webkit-flex: none;
  padding: 0 0.32rem;
}
// 设置tabs 全部靠左，并在左边设置10px间距
/deep/ .van-tabs__wrap:first-child{
  margin-left: 10px;
}
/deep/ .van-tabs__nav--line.van-tabs__nav--complete{
  padding-right: 0;
  padding-left: 0;
}
//条件选中的文字样式
/deep/.van-tab--active {
  background-color: var(--minor-color);   // 选中框背景色
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  //line-height: 66px;
  letter-spacing: 0px;
  color: var(--title-color);
}

/deep/.van-tabs__line {
  background: none;
}

// 下方筛选出来的影片样式
/deep/.van-image__img {
  height: 164px;
}

.screenMovie {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.screenMovie_cell {
  margin-bottom: 4px;
  margin-left: 7px;
  width: 116px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.duban {
  position: absolute;
  bottom: 0.9rem;
  right: 0.2rem;
  color: red;
  font-size: 14px;
  font-weight: bold;
}

.van-tabs {
  padding-bottom: 8px;  // 每行筛选条件的间距
}

.trainee-idol {
  font-size: 14px;
  line-height: 24px;
  color: var(--minor-text-color);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.back-top{
  z-index: 999;
  position: fixed;
  right: 10px;
  bottom: 70px;
}
.image_item{
  width: 116px;
  display: block;
  border-radius: 3px;
  overflow: hidden;
}
.tabs-content{
  padding-top: 7px;
}
/deep/ .van-tabs--line .van-tabs__wrap{
  height: 0.8rem!important;
}
// 去除下方间距
/deep/ .van-tabs:first-child{
  padding-bottom: 0;
}
/deep/.van-tabs__nav--line {
  //padding-bottom: ''
}
/deep/.van-image__error,
.van-image__loading {
  background-color: transparent;
}
/deep/.van-hairline--bottom::after {
  border-bottom: none;
}
</style>
