import request from '@/utils/requests'
import qs from 'qs'


// 筛选
export const getTypeList = () => {
  return request({
    url: '/type/get_list',
    method: 'post',
  })
}

// 筛选后的数据
export const getScreen = data => {
  return request({
    url: '/search/screen',
    method: 'post',
    data:qs.stringify(data)

  })
}